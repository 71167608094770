/* eslint-disable react/destructuring-assignment */
import { navigate } from 'gatsby';
import * as React from 'react';
import tw, { styled } from 'twin.macro';
import { TCareer } from '../../../pages/career';
import { Button, ButtonType } from '../../Common/Button';

export interface Props {
  job: TCareer;
}

const Container = styled.div(() => [tw`relative col-span-3 flex md:col-span-1 flex-col`]);

const Title = styled.h4(() => [
  tw`font-semibold w-full text-2xl md:text-xl text-orange text-left mt-0 md:mb-1 md:h-[84px] xl:h-[76px]`
]);

const Desc = styled.span(() => [tw`font-light mt-0 text-[16px] leading-6 mb-4 md:mb-8 h-auto`]);

const UpdatedAt = styled.p(() => [tw`font-semibold text-[14px] text-spaceGray mb-1`]);

const formatDate = (dateTime: Date) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  if (dateTime) {
    const month = months[dateTime.getMonth()];
    return `${month} ${dateTime.getDate()} ${dateTime.getFullYear()}`;
  }
  return undefined;
};

const JobCard: React.FC<Props> = (props) => {
  return (
    <Container className="p-4 transform transition hover:bg-gray-50 rounded-lg">
      <UpdatedAt>{formatDate(new Date(props.job.node.updatedAt || props.job.node.createdAt))}</UpdatedAt>
      <Title>
        <span className="line-clamp-2">{props.job.node.title}</span>
      </Title>
      <Desc>
        <span className="line-clamp-4">{props.job.node.description}</span>
      </Desc>
      <div className="flex flex-grow items-end">
        <button
          type="button"
          onClick={() => navigate(`/career/${props.job.node.client}`)}
          className="px-6 py-2 text-orange bg-white border-orange border-[2px] w-max rounded-[5px]"
        >
          View details
        </button>
      </div>
    </Container>
  );
};

export default JobCard;
