import { TeamName } from '../../../utils/enums';

interface TTeamSelector {
  current: TeamName;
  setCurrent: (teanName: TeamName) => void;
  className?: string;
  isRenderAllTeam?: boolean;
}

interface Team {
  name: TeamName;
}

const Teams: Team[] = [
  { name: TeamName.ENGINEERING },
  { name: TeamName.MARKETING },
  { name: TeamName.DESIGN },
  { name: TeamName.PROJECT },
  { name: TeamName.CUSTOMER_SUPPORT },
  { name: TeamName.PEOPLE_AND_OFFICE },
];

const RenderTeam: React.FC<{ team: Team; current: TeamName; handleClickTeam: (teamName: TeamName) => void; }> = ({ team, current, handleClickTeam }) => {
  return (
    <div
      className={`rounded-[5px] text-base px-2 py-1 md:px-4 md:py-3 flex items-center justify-center cursor-pointer transform transition hover:scale-105 ${team.name === current ? 'bg-orange text-white' : 'bg-white text-spaceGray'}`}
      onClick={() => handleClickTeam(team.name)}
    >
      <span className="font-semibold">{team.name}</span>
    </div>
  );
};

const TeamSelector: React.FC<TTeamSelector> = ({ current, setCurrent, className, isRenderAllTeam }) => {
  return (
    <div className={`w-full flex flex-wrap items-center gap-2 md:gap-8 ${className}`}>
      {isRenderAllTeam && (
        <RenderTeam
          team={{ name: TeamName.ALL }}
          current={current}
          handleClickTeam={setCurrent}
        />
      )}
      {Teams.map((team) => (
        <RenderTeam
          key={team.name}
          team={team}
          current={current}
          handleClickTeam={setCurrent}
        />
      ))}
    </div>
  );
};

export default TeamSelector;
