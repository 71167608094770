import * as React from 'react';
import tw, { styled } from 'twin.macro';
import WorkWithUs from '../../../assets/pages/WorkWithUs.png';
import MailIcon from '../../../assets/pages/contact-us/mail.svg';
import { PageHeaderText } from '../../../utils/TextLabel';

const StyledContainer = styled.section(() => [
  tw`relative md:max-w-pageContainerSize w-full px-pageContainerSpace xl:px-0 mx-auto pt-10 md:pt-20 pb-10 lg:pb-0 sm:mb-20 md:mb-8`
]);

const Row = styled.div(() => [tw`grid grid-cols-2 gap-3`]);

const Col = styled.div(() => [tw`col-span-2 md:col-span-1 flex flex-col justify-center`]);

const WorkWithUsImage = styled.img(() => [tw`w-full`]);

// const Title = styled.h2(() => [
//   tw`flex w-full text-[24px] lg:text-[40px] mt-0 lg:justify-start font-bold text-spaceGray md:mb-8 mb-2`
// ]);

// const Description = styled.p(() => [tw``]);

const Contact = styled.p(() => [tw`text-[16px] font-semibold text-spaceGray flex gap-2 items-center`]);

const EmailUs = styled.span(() => [tw``]);

const Email = styled.a(() => tw`text-orange hidden md:block`);

interface WhyWorkWithUsSectionProps {
  className?: string;
}

const WhyWorkWithUsSection: React.FC<WhyWorkWithUsSectionProps> = ({ className }) => (
  <StyledContainer className={className}>
    <Row>
      <Col>
        <PageHeaderText>Why work with us?</PageHeaderText>
        <p className="my-7 leading-7 text-sm md:text-lg font-light w-[90%]">
          We are a tech-savvy and business innovation builder team, passionate about the dynamics of the technology-business mindset. Also, focus on impact with a sense of ownership and
          do better things for sharing and caring for people around. Working with a team that shares your values and goals is what you are looking for, right?
          If yes, let’s be one of our team!
        </p>
        <Contact>
          <EmailUs>Email us:</EmailUs>
          {/* email, only appear on mobile view */}
          <a
            className="md:hidden"
            href="mailto:wanttowork@orangecapinnovative.com"
          >
            <img
              alt="Contact via email"
              src={MailIcon}
              className="w-7 h-7"
            />
          </a>
          <Email href="mailto:wanttowork@orangecapinnovative.com">wanttowork@orangecapinnovative.com</Email>
        </Contact>
      </Col>
      <Col>
        <WorkWithUsImage src={WorkWithUs} />
      </Col>
    </Row>
  </StyledContainer>
);

export default WhyWorkWithUsSection;
