import * as React from 'react';
import { graphql } from 'gatsby';
import Page from '../components/Common/Page';
import IndexLayout from '../layouts';
import WhyWorkWithUsSection from '../components/pages/CareerPage/WhyWorkWithUsSection';
import JobsOpeningSection from '../components/pages/CareerPage/JobsOpeningSection';
import { TeamName } from '../utils/enums';

export interface TCareer {
  node: {
    client: string;
    createdAt: string;
    updatedAt: string;
    description: string;
    jobResponsibilities: string;
    qualifications: string;
    team: {
      id: number;
      name: TeamName;
    };
    title: string;
  };
}

const CareerPage: React.FC<any> = ({ data }) => (
  <IndexLayout transparent>
    <Page>
      <WhyWorkWithUsSection />
      <JobsOpeningSection data={data?.allStrapiCareers?.edges as TCareer[]} />
    </Page>
  </IndexLayout>
);

export default CareerPage;

export const query = graphql`
  query CareersQuery {
    allStrapiCareers {
      edges {
        node {
          title
          createdAt
          updatedAt
          description
          client
          qualifications
          jobResponsibilities
          team {
            id
            name
          }
        }
      }
    }
  }
`;
