import React, { useState } from 'react';
import tw, { styled } from 'twin.macro';
import { TCareer } from '../../../pages/career';
import { TeamName } from '../../../utils/enums';
import { PageHeaderText } from '../../../utils/TextLabel';
import TeamSelector from '../OurTeamPage/TeamSelector';
import JobCard from './JobCard';

const StyledContainer = styled.section(() => [
  tw`relative md:max-w-pageContainerSize w-full px-pageContainerSpace xl:px-0 mx-auto pt-0 sm:mb-20 md:mb-14 xl:mb-0`
]);

const OpeningJobsContainer = styled.div(() => [
  tw`grid grid-cols-3 overflow-x-scroll gap-x-28 gap-y-12 md:gap-y-20 md:gap-x-8 xl:gap-x-28 bg-transparent pb-18 xl:pb-28`
]);

const Title = styled.h2(() => [tw`flex w-full text-2xl lg:text-[32px] mt-0 font-bold text-spaceGray md:mb-10 mb-2`]);

interface JobsOpeningSectionProps {
  className?: string;
  data: TCareer[];
}

const JobsOpeningSection: React.FC<JobsOpeningSectionProps> = ({ className, data }) => {
  const [current, setCurrent] = useState(TeamName.ALL);

  let jobs = data.filter((job) => job.node.team?.name === current);
  if (current === TeamName.ALL) {
    jobs = data;
  }

  return (
    <StyledContainer className={className}>
      <PageHeaderText>Jobs opening</PageHeaderText>
      <TeamSelector
        current={current}
        setCurrent={(teamName) => setCurrent(teamName)}
        className="mt-12 md:mt mb-10"
        isRenderAllTeam
      />
      <OpeningJobsContainer>
        {jobs &&
          jobs.map((job) => (
            <JobCard
              key={job.node.title}
              job={job}
            />
          ))}
      </OpeningJobsContainer>
    </StyledContainer>
  );
};

export default JobsOpeningSection;
